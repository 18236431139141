import { Flex, LinkBox, Stack, Text } from "@biblioteksentralen/react";
import { isLocalizedField } from "@libry-content/localization";
import { getPath } from "../../utils/getPath";
import { useTranslation } from "../../utils/hooks/useTranslation";
import onDesktop from "../../utils/onDesktop";
import InternalLinkOverlay from "../InternalLinkOverlay";
import { useCommonData } from "../layout/CommonDataProvider";
import EmployeeAvatar from "./EmployeeAvatar";
import { SanityImagePreservingAspectRatio } from "../images/SanityImagePreservingAspectRatio";
import { PaletteColor } from "@libry-content/pdf";
import { ResolvedCreatedBySummary, ResolvedEmployee, ResolvedSiteSummary } from "@libry-content/common";

interface Props {
  createdBy?: ResolvedCreatedBySummary;
  size?: "sm" | "md";
  paletteColor?: PaletteColor;
}

const isEmployee = (createdBy: Props["createdBy"]): createdBy is ResolvedEmployee => createdBy?._type === "employee";
const isSite = (createdBy: Props["createdBy"]): createdBy is ResolvedSiteSummary => createdBy?._type === "site";

const CreatedByTag = ({ createdBy, size = "sm", paletteColor }: Props) => {
  const { t, ts } = useTranslation();
  const { site } = useCommonData();
  const showEmployee = isEmployee(createdBy) && !!createdBy?.showEmployee;
  const libraryOrSite = (isEmployee(createdBy) ? createdBy.affiliations?.[0]?.entity : createdBy) ?? site;

  return (
    <LinkBox>
      <Flex gap=".5rem" role="group">
        <CreatedByImage createdBy={createdBy} size={size} paletteColor={paletteColor} />
        <Stack
          spacing={0}
          fontSize={size === "sm" ? ".7rem" : "1rem"}
          justifyContent={isSite(createdBy) ? "center" : ""}
        >
          {showEmployee && (
            <InternalLinkOverlay
              href={getPath(createdBy)}
              _hover={onDesktop({ textDecoration: "underline" })}
              zIndex={10}
            >
              <Text fontWeight={600}>{createdBy.name}</Text>
            </InternalLinkOverlay>
          )}
          {((!isEmployee(createdBy) && !isSite(createdBy)) || (createdBy._type === "employee" && !showEmployee)) && (
            <Text fontWeight={600}>{t("Ansatt")}</Text>
          )}
          {libraryOrSite && (
            <Text noOfLines={1}>
              {isLocalizedField(libraryOrSite?.name) ? ts(libraryOrSite?.name) : libraryOrSite?.name}
            </Text>
          )}
        </Stack>
      </Flex>
    </LinkBox>
  );
};

const CreatedByImage = ({ createdBy, size = "sm", paletteColor }: Props) => {
  if (isSite(createdBy))
    return (
      <SanityImagePreservingAspectRatio
        image={createdBy.coatOfArms}
        resolution={200}
        width=".9rem"
        boxShadow="none"
        borderRadius="none"
      />
    );

  const showEmployee = isEmployee(createdBy) && !!createdBy?.showEmployee;

  return (
    <EmployeeAvatar
      employee={showEmployee ? createdBy : undefined}
      size={size === "sm" ? "2rem" : "3rem"}
      resolution={200}
      hideCaption
      paletteColor={paletteColor}
    />
  );
};

export default CreatedByTag;
