import { Box, colors, HStack, TagProps } from "@biblioteksentralen/react";
import React from "react";
import {
  TargetAudienceCode,
  targetAudienceCodeIndex,
  targetAudiences as targetAudienceLabels,
} from "@libry-content/common";
import { sort } from "radash";
import { useTranslation } from "../../utils/hooks/useTranslation";

type Props = {
  mediaTypes?: string[];
  targetAudiences?: TargetAudienceCode[];
};

export const MediaTypesAndTargetAudiences = ({ mediaTypes, targetAudiences }: Props) => {
  return (
    <HStack wrap="wrap" spacing={1}>
      <MediaTypeTags mediaTypes={mediaTypes} />
      <TargetAudienceTags targetAudiences={targetAudiences} />
    </HStack>
  );
};

export const MediaTypeTags = ({ mediaTypes }: { mediaTypes?: string[] }) => {
  if (!mediaTypes) return null;

  return (
    <>
      {mediaTypes?.sort().map((mediaType, i) => (
        <>{mediaType && <RecommendationTag key={i}>{mediaType}</RecommendationTag>}</>
      ))}
    </>
  );
};

export const TargetAudienceTags = ({ targetAudiences }: { targetAudiences?: TargetAudienceCode[] }) => {
  const { ts } = useTranslation();

  return (
    <>
      {targetAudiences?.length &&
        sort(targetAudiences, targetAudienceCodeIndex).map((code, i) => (
          <RecommendationTag key={i} backgroundColor={colors.grey80} color="white">
            {ts(targetAudienceLabels[code])}
          </RecommendationTag>
        ))}
    </>
  );
};

// TODO: Reuse TypeTag component
export const RecommendationTag = ({ children, ...tagProps }: { children: React.ReactNode } & TagProps) => (
  <Box
    backgroundColor={colors.grey30}
    color={colors.black}
    padding=".1rem .2rem"
    textTransform="uppercase"
    borderRadius=".13rem"
    fontWeight="semibold"
    lineHeight="normal"
    fontSize="2xs"
    {...tagProps}
  >
    {children}
  </Box>
);
