import { Button, ButtonProps, Icon } from "@biblioteksentralen/react";
import NextLink from "next/link";
import { ReactNode } from "react";
import { ArrowRight } from "react-feather";
import { LocalizedNextLink } from "../LocalizedNextLink";

type Props = {
  href: string;
  children: ReactNode;
  iconLeft?: boolean;
  withoutLocale?: boolean;
} & ButtonProps;

function SeMerLenke({ children, iconLeft, withoutLocale, ...chakraProps }: Props) {
  return (
    <Button
      height="auto"
      padding="0.5em 0.75em"
      whiteSpace="normal"
      sx={{
        "&:hover svg": {
          transform: "translateX(.25em)",
          transition: "transform 0.2s",
        },
      }}
      {...{
        [iconLeft ? "leftIcon" : "rightIcon"]: (
          <Icon as={ArrowRight} transform="translateX(0)" transition="transform .5s" />
        ),
      }}
      as={withoutLocale ? NextLink : LocalizedNextLink}
      variant="secondary"
      {...chakraProps}
    >
      {children}
    </Button>
  );
}

export default SeMerLenke;
